import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import illustration from './svg/Layer 1 2.svg';
import errorimage from './svg/Group 171.svg';

export default function VerifyMail() {
	let [searchParams] = useSearchParams();

	let [verified, setVerified] = useState(false);
	let [error, setError] = useState("");
	let [message, setMessage] = useState("");

	const baseUrl = "https://us-central1-passcoder-identity.cloudfunctions.net/endpoints/ng_app_internal"

	function emailVerify(email) {
		axios.post(baseUrl + "/user/email/verify", {
			email: email
		}).then((response) => {
			setVerified(true);
			setMessage(response.data.message);
		}).catch((err) => {
			if (err.response.status === 422) {
				setError(err.response.data.data[0].msg);
			} else {
				setError(err.response.data.message);
			}
		})
	}

	useEffect(() => {
		emailVerify(searchParams.get('email'))
	}, [searchParams])

	return (
		<>
			{
				verified
					?
					<div className='extended-first-div'>
						<h1 className='extended-first-div-h1'>Your Passcoder email has been verified successfully</h1>
						<div>
							<img src={illustration} alt='Upload Successful' />
						</div>
						<h1>{message}</h1>
						<br></br>
						<br></br>
						<h1>You can now login via the app</h1>
					</div>
					:
					<div className='extended-first-div'>
						<p className='extended-first-div-p'>Email Verification</p>
						{
							error
								?
								<div>
									<img style={{ height: 300, padding: 20 }} src={errorimage} alt='Verification failed' />
								</div>
								:
								<div>
									...
								</div>
						}

						<h1 className='extended-first-div-h1'>{error ? error : "Verifying Email ..."}</h1>
					</div>
			}
		</>
	)
}