import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import illustration from './svg/Layer 1 2.svg';
import errorimage from './svg/Group 171.svg';

export default function SignUpSuccess() {

	return (
		<>
			<div className='extended-first-div'>
				<h1 className='extended-first-div-h1'>Sign up successful</h1> <br></br>
				<div>
					<img src={illustration} alt='Upload Successful' />
				</div>
				<br></br>
				<h1>Please check your mail box for the verification email. Thank you.</h1>
			</div>
		</>
	)
}