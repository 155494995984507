import { Route, Routes } from 'react-router-dom';
import VerifyMail from './screens/VerifyMail';
import NotFound from './screens/NotFound';
import SignUp from './screens/SignUp';
import SignUpSuccess from './screens/SignUpSuccess';

function App() {
  return (
    <div>
        <Routes>
          <Route path="user/email/verify" element={ <VerifyMail /> } />
          <Route path="/signup/success" element={<SignUpSuccess />} />
          <Route path="/signup" element={<SignUp />} /> 
          <Route path="/signup/:ref" element={<SignUp />} />
          <Route path="*" element={<NotFound />}/>
        </Routes>
    </div>
  );
}

export default App;
