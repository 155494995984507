import Svg from './svg/3d-stripy-man-working-on-the-laptop-behind-the-desk.svg';
export default function NotFound() {
    return (
        <>
            <div className="extended-first-div">
                <div className='request-div mt-9'>
                    <h1 className='illustration-whoops-h1'><span style={{ color: '#292482' }}>Whoops... Page not found ...</span> Nothing to see here</h1>
                    <p className='illustration-whoops-p' >The Page you visited does not exist</p>
                    <img
                        src={Svg} alt={Svg}
                        className='illustration-whoops' />
                </div>
            </div>
        </>
    )
}