import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Svg from "./svg/Component 1154.svg";
import axios from 'axios'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const SignUp = () => {

	const { ref } = useParams();

	const baseUrl = "https://us-central1-passcoder-identity.cloudfunctions.net/endpoints/ng_app_internal";

	const [userSignUp, setuserSignUp] = useState({
		firstname: "",
		lastname: "",
		middlename: "",
		country: "Nigeria",
		dateofbirth: "",
		phonenumber: "",
		password: "",
		confirmPassword: "",
		email: "",
		gender: "Male"
	});

	const [error, setError] = useState("");
	const [fetchingData, setFetchingData] = useState(false);

	let [pwordtype, setPwordtype] = useState("password");

	function togglepass() {
		if (pwordtype === "password") {
			setPwordtype("text");
		} else {
			setPwordtype("password");
		}
	}

	const navigate = useNavigate();

	function handleChange(e) {
		let userData = e.target.value;
		setuserSignUp({ ...userSignUp, [e.target.name]: userData });
	}

	const test_all_regex = (data, regex) => {
		if (!data) {
			return false;
		}

		const valid = regex.test(data);
		if (!valid) {
			return false;
		}

		return true;
	};

	const validate_phone_number = (url) => {
		const tester = /^(?:\+(?:[0-9]●?){6,14}[0-9])$/;
		return test_all_regex(url, tester);
	};

	function handleSumit(e) {
		e.preventDefault();
		setFetchingData(true);
		setError("");

		const _date_ = new Date(userSignUp.dateofbirth);
		const _date = _date_.getFullYear() + "-" + ((_date_.getUTCMonth() + 1) < 10 ? "0" + (_date_.getUTCMonth() + 1) : (_date_.getUTCMonth() + 1)) + "-" + (_date_.getDate() < 10 ? "0" + _date_.getDate() : _date_.getDate());

		let body = userSignUp.middlename ? {
			firstname: userSignUp.firstname,
			lastname: userSignUp.lastname,
			middlename: userSignUp.middlename,
			country: userSignUp.country,
			dob: _date,
			phone_number: userSignUp.phonenumber,
			gender: userSignUp.gender,
			email: userSignUp.email,
			password: userSignUp.password,
			confirmPassword: userSignUp.confirmPassword,
		} : {
			firstname: userSignUp.firstname,
			lastname: userSignUp.lastname,
			country: userSignUp.country,
			dob: _date,
			phone_number: userSignUp.phonenumber,
			gender: userSignUp.gender,
			email: userSignUp.email,
			password: userSignUp.password,
			confirmPassword: userSignUp.confirmPassword,
		};

		let endpoint = ref ? "/auth/user/signup/ref" : "/auth/user/signup";

		axios.post(baseUrl + endpoint, body)
			.then((response) => {
				navigate('/signup/success');
			})
			.catch((err) => {
				setFetchingData(false);	
				if(err.response.status === 422) {
					setError(err.response.data.data[0].msg);
				} else {
					setError(err.response.data.message);
				}
			});

	};

	return (
		<div className="max-w-[700px] mx-auto my-16 p-4">
			<div>
				<p
					style={{ color: "#292482", fontWeight: "bold" }}
					className="py-1 flex content-center justify-center text-center "
				>
					<img
						className="py-1 flex content-center justify-center w-10"
						alt={Svg}
						src={Svg}
					/>
				</p>
				<h1 className="text-2xl text-center font-bold py-2 mt-2">
					Sign up for Passcoder
				</h1>
				<p
					style={{ color: "gray", fontWeight: "bold" }}
					className="py-1 text-center "
				>
					Already have an account?{" "}
					<p
						style={{ color: "#292482", fontWeight: "bold" }}
						className=""
					>
						Sign in via app.
					</p>
				</p>
			</div>
			<br />

			<br />
			<form onSubmit={handleSumit}>

				<div className="flex flex-col py-2 w-200">
					<label className="py-2 font-medium">First Name</label>
					<input
						minLength={1}
						maxLength={50}
						value={userSignUp.firstname}
						name="firstname"
						type="text"
						className="border p-3  bg-gray-200 placeholder-gray-350"
						required
						onChange={handleChange}
						placeholder="Enter legal first name"
					/>
				</div>

				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Middle Name</label>
					<input
						minLength={1}
						maxLength={50}
						value={userSignUp.middlename}
						name="middlename"
						type="text"
						className="border p-3 bg-gray-200 placeholder-gray-350"
						placeholder="Enter legal middle name"
						onChange={handleChange}
					/>
				</div>

				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Last Name</label>
					<input
						minLength={1}
						maxLength={50}
						value={userSignUp.lastname}
						name="lastname"
						type="text"
						className="border p-3  bg-gray-200 placeholder-gray-350"
						required
						placeholder="Enter legal last name"
						onChange={handleChange}
					/>
				</div>

				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Country</label>
					<select
						name="country"
						className="border p-3 bg-gray-200 placeholder-gray-350"
						required
						onChange={handleChange}
						value={userSignUp.country}
					>
						<option disabled className="gray-350">Choose your Country</option>
						<option value="Nigeria" name='Nigeria'>Nigeria</option>
					</select>
				</div>

				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Gender</label>
					<select
						name="gender"
						className="border p-3 bg-gray-200 placeholder-gray-350"
						required
						onChange={handleChange}
						value={userSignUp.gender}
					>
						<option disabled className="gray-350">Choose your gender</option>
						<option defaultValue value="Male" name='male'>Male</option>
						<option value="Female" name='female'>Female</option>
					</select>
				</div>
				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Date of Birth</label>
					<input
						value={userSignUp.dateofbirth}
						name="dateofbirth"
						type="date"
						className="border p-3 bg-gray-200 date:text-gray-350"
						required
						onChange={handleChange}
					/>
				</div>
				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Phone Number</label>
					<input
						value={userSignUp.phonenumber}
						name="phonenumber"
						type="tel"
						className="border p-3  bg-gray-200 placeholder-gray-350"
						required
						onChange={handleChange}
						placeholder="With country code (+234)"
					/>
					<div> {validate_phone_number(userSignUp.phonenumber) ? <p> </p> : <p style={{ color: "red" }}> Invalid Phone Number</p>}  </div>
				</div>
				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Email Address</label>
					<input
						value={userSignUp.email}
						name="email"
						type="email"
						className="border p-3  bg-gray-200 placeholder-gray-350"
						required
						onChange={handleChange}
						placeholder="Enter your Email Address"
					/>
				</div>

				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Create a Password</label>
					<input
						value={userSignUp.password}
						name="password"
						type={pwordtype}
						className="border p-3  bg-gray-200 placeholder-gray-350"
						required
						onChange={handleChange}
						placeholder="Create Password"
					/>
					<div style={{ position: 'relative', width: 20, left: '92%', top: -40 }}>
						{
							pwordtype === "password" ?
								<IoMdEye size={25} onClick={togglepass} /> : 
								<IoMdEyeOff size={25} onClick={togglepass} />
						}
					</div>
				</div>

				<div className="flex flex-col py-2">
					<label className="py-2 font-medium">Confirm your Password</label>
					<input
						value={userSignUp.confirmPassword}
						name="confirmPassword"
						type={pwordtype}
						className="border p-3  bg-gray-200 placeholder-gray-350"
						required
						onChange={handleChange}
						placeholder="Confirm your password"
					/>
					<div style={{ position: 'relative', width: 20, left: '92%', top: -40 }}>
						{
							pwordtype === "password" ?
								<IoMdEye size={25} onClick={togglepass} /> :
								<IoMdEyeOff size={25} onClick={togglepass} />
						}
					</div>
					{userSignUp.confirmPassword !== userSignUp.password ? (
						<div
							style={{
								color: "red"
							}}
						>
							<p style={{ color: "red" }}>
								Passwords do not match
							</p>
						</div>) : (
						<p style={{ fontSize: "0.8em", color: "blue" }}> </p>
					)}
				</div>
				<br />

				{error ? (
					<div
						style={{
							width: "100%",
							backgroundColor: "rgba(255, 0, 0, 0.122)",
							paddingRight: "1em",
							paddingLeft: "1em",
							paddingBottom: "0.5em",
							borderLeft: "red solid 0.3em",
							paddingTop: "0.5em",
						}}
					>
						<p style={{ color: "red" }}>
							{error}
						</p>
					</div>
				) : (
					<p style={{ fontSize: "0.8em", color: "blue" }}> </p>
				)}

				{
					fetchingData
						?
						<div className="border border-blue-500 bg-[#cccccc] hover:bg-blue-500 w-full p-4 my-2 text-white" style={{ textAlign: 'center' }}>
							Loading ...
						</div>
						:
						<button className="border border-blue-500 bg-[#292482] hover:bg-blue-500 w-full p-4 my-2 text-white">
							Get Access
						</button>
				}
				<br />
				<br />
				<br />

			</form>
		</div>
	);
};

export default SignUp;
